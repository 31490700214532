import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import FadeIn from 'react-fade-in'
import ReactGA from 'react-ga4'

import lgAudio from './assets/leegandhi.mp3'

const CONFETTI_THRESHOLD = 7 // in seconds
const CONFETTI_DURATION = 15 // in seconds

ReactGA.initialize('G-8M6J0HGZY5')
ReactGA.send('pageview')

export default function App() {
  const { width, height } = useWindowSize()
  const [audioStatus, setAudioStatus] = useState(false)
  const [audioEndStatus, setAudioEndStatus] = useState(false)
  const [audioProgress, setAudioProgress] = useState({})
  const [confettiStatus, setConfettiStatus] = useState(false)
  const [followUpStatus, setFollowUpStatus] = useState(false)
  let confettiTimeout = null

  useEffect(() => {
    if (!audioProgress || !audioProgress.playedSeconds || audioProgress.playedSeconds < CONFETTI_THRESHOLD) {
      setConfettiStatus(false)
      return
    }

    setConfettiStatus(true)
  }, [audioProgress])

  useEffect(() => {
    if (!confettiStatus) {
      clearTimeout(confettiTimeout)
      return
    }

    confettiTimeout = setTimeout(() => {
      setConfettiStatus(false)
    }, CONFETTI_DURATION * 1000)
  }, [confettiStatus])

  useEffect(() => {
    if (audioEndStatus) setFollowUpStatus(true)
  }, [audioEndStatus])

  return (
    <>
      <div className="py-4" />
      <section className="max-w-6xl m-auto py-10 px-4 text-center">
        <div className="mb-8">
          <div className="mb-1 text-xl font-bold text-illini-orange">Volume up!</div>
          <div className="text-xs text-gray-400">Nothing explicit, just NSFW...</div>
          {
            !audioStatus && audioProgress.playedSeconds && !audioEndStatus ? (
              <div className="mt-1 text-sm text-red-400">Why did you stop...?</div>
            ) : ''
          }
        </div>
        <ReactPlayer
          url={lgAudio}
          width="100%"
          height="50px"
          className="m-auto max-w-md px-2 sm:px-4"
          controls
          onPlay={() => setAudioStatus(true)}
          onPause={() => setAudioStatus(false)}
          onEnded={() => setAudioEndStatus(true)}
          onProgress={(data) => setAudioProgress(data)}
        />
        <div className="my-4">
          <div className="mb-1 text-xs text-gray-400">More awaits near the end of the audio!</div>
          <div className="text-xs text-gray-400">
            (Made with&nbsp;
            <a href="https://aws.amazon.com/polly/" className="underline hover:text-illini-orange transition cursor-pointer">Amazon Polly</a>
            &nbsp;Neural)
          </div>
        </div>
        {
          // eslint-disable-next-line no-nested-ternary
          confettiStatus ? (
            <div>
              <Confetti
                width={width}
                height={height}
              />
            </div>
          ) : audioEndStatus ? (
            <div className="text-xs text-illini-blue opacity-80">
              <button type="button" onClick={() => setConfettiStatus(true)}>More Confetti! Why Not?</button>
            </div>
          ) : ''
        }
      </section>
      <section className="max-w-6xl m-auto py-6 px-4 text-center">
        {
          followUpStatus ? (
            <FadeIn className="relative w-full">
              <ReactPlayer url="https://youtu.be/nxyiccilVKM" className="m-auto max-w-full w-full" controls />
            </FadeIn>
          ) : ''
        }
      </section>
    </>
  )
}
